<template>
	<div>
		<Loader v-if="organizationsLoad" />
		<div v-else class="container mx-auto mt-5 mt-10">
			<div class="md:grid md:grid-cols-1 md:gap-6">
				<div class="md:col-span-1">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium text-gray-900 leading-6">
							{{ $i18n("org.title") }} {{ method === "update" ? organization.name : "" }} -
							{{ $i18n(`action.${method}` ,'d') }}
						</h3>
					</div>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<form action="#">
						<div class="overflow-hidden shadow sm:rounded-md">
							<div class="px-4 py-5 bg-white sm:p-6">
								<div class="grid grid-cols-6 gap-6">
									<div class="col-span-6 sm:col-span-3">
										<label for="email" class="block text-sm font-medium text-gray-700">{{
											$i18n("misc.name")
										}}</label>
										<input
											v-model="organization.name"
											type="text"
											name="name"
											id="name"
											autocomplete="given-name"
											class="block w-full mt-1 border-gray-300 focus:ring-blue-500 focus:border-blue-500 shadow-sm sm:text-sm rounded-md"
										/>
									</div>
									<div class="col-span-6 sm:col-span-3">
										<label for="email" class="block text-sm font-medium text-gray-700">{{
											$i18n("org.max-users")
										}}</label>
										<input
											v-model="maxUsersTyped"
											type="number"
											name="name"
											id="name"
											autocomplete="given-name"
											class="block w-full mt-1 border-gray-300 focus:ring-blue-500 focus:border-blue-500 shadow-sm sm:text-sm rounded-md"
										/>
									</div>
									<div class="col-span-6 sm:col-span-3">
										<label for="active" class="block text-sm font-medium text-gray-700"
											>{{ $i18n("org.status.active") }}
										</label>
										<input
											type="checkbox"
											v-model="organization.active"
											id="active"
											name="active"
											class="mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										/>
									</div>
								</div>
							</div>
							<div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
								<button
									@click="update"
									type="button"
									class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent shadow-sm rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									{{ $i18n("action.save") }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
	name: "Organization",
	props: {
		id: {
			type: String,
		},
		method: {
			type: String,
		},
	},
	data() {
		return {
			organization: { max_users: 0 },
			error: false,
		};
	},
	components: {
		Loader,
	},
	computed: {
		...mapGetters("organizations", ["organizationsLoad"]),
		maxUsersTyped: {
			get () {
				return Number(this.organization.max_users)
			},
			set (value) {
				this.organization.max_users = Number(value)
			}
		}
	},
	methods: {
		...mapActions("organizations", ["fetchOrganizationById", "updateOrganizationById", "createOrganization"]),
		async update() {
			try {
				if (this.method === "update") {
					await this.updateOrganizationById({
						organizationId: this.organization.id,
						payload: this.organization,
					});
				} else if (this.method === "create") {
					await this.createOrganization(this.organization);
				}
				this.$router.push({ name: "Organizations" });
			} catch (error) {
				console.debug(error);
			}
		},
	},
	async created() {
		try {
			if (this.method === "update") {
				this.organization = await this.fetchOrganizationById(this.id);
			}
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
